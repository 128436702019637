<template>
    <div class="">
        <ath-card class="my-5" :title="this.$t('transAnal.percentageOfSales')" style="margin-top: 0px!important; margin-bottom: 20px!important;">
            <template v-slot:card-title-action >
                <v-sheet style="font-size:12px;line-height:15px;color: white;background: #C4161C;" >
                {{$t('transAnal.remark1')}}</v-sheet>
            </template>

            <template v-slot:card-body>
                <v-row >
                    <v-col md="2">
                        <ath-radio style="width:80%;" class="justify-space-between"
                            v-model="btnValue"
                            :align="align"
                            :items="btnItems"
                            :direction="direction"
                            @change="changeQueryType"
                        />
                    </v-col>

                    <v-col>
                        <v-autocomplete  v-model="timeFrame" :label="$t('transAnal.timeFrame')"
                            :items="timeFrameItems"
                            :item-text="'label'"
                            :item-value="'value'"
                            @change="changeTimeFrame"/>
                    </v-col>
                    <v-col>
                        <v-autocomplete  v-model="merchantName" :label="$t('transAnal.merchant')"
                            :items="merchantNameItems"
                            :item-text="'label'"
                            :item-value="'value'"
                            @change="changeMerchantName"/>
                    </v-col>
                </v-row>

                <v-row>
                    <div ref="chart" style="width: 100%; height: 400px;"/>
                </v-row>
            </template>
        </ath-card>
    </div>
</template>

<script>
export default {
    name: 'PaymentChannelCard',
    components: {
    },
    props: {
        lang: {
            type: String,
            default: '',
            required: true
        },
        merchants: {
            type: Array,
            default: [],
            required: true
        }
    },
    data() {
        return {
            timeFrameItems: [
                { label: this.$t('transAnal.today'), value: 'day' },
                { label: this.$t('transAnal.last7Day'), value: '7day' },
                { label: this.$t('transAnal.currentMonth'), value: 'month' },
                { label: this.$t('transAnal.last3Month'), value: '3month' },
                { label: this.$t('transAnal.last6Month'), value: '6month' },
                { label: this.$t('transAnal.currentYear'), value: 'year' },
                { label: this.$t('transAnal.lastYear'), value: 'lastyear' },
            ],
            merchantNameItems: this.merchants,
            timeFrame: "day",
            merchantName: "",
            queryType: "amount",  //amount/number
            btnItems: [
				{ label: this.$t('transAnal.amount'), value: 'amount', disabled: false },
				{ label: this.$t('transAnal.number'), value: 'number', disabled: false },
			],
            chart: null,
            chartData: [],
            btnValue: "amount",
            direction: 'row',
            isLoading: false
        }

    },
    watch: {
        merchants: {
            handler(newValue) {
                this.merchantNameItems = newValue;
            },
            deep: true
        },
        chartData: {
            handler(newValue) {
                this.renderChart(newValue);
            },
            deep: true
        },
        lang: {
            handler(newValue) {
                this.timeFrameItems = [
                    { label: this.$t('transAnal.today'), value: 'day' },
                    { label: this.$t('transAnal.last7Day'), value: '7day' },
                    { label: this.$t('transAnal.currentMonth'), value: 'month' },
                    { label: this.$t('transAnal.last3Month'), value: '3month' },
                    { label: this.$t('transAnal.last6Month'), value: '6month' },
                    { label: this.$t('transAnal.currentYear'), value: 'year' },
                    { label: this.$t('transAnal.lastYear'), value: 'lastyear' },
                ];
                this.btnItems = [
                    { label: this.$t('transAnal.amount'), value: 'amount', disabled: false },
                    { label: this.$t('transAnal.number'), value: 'number', disabled: false },
                ];

                this.getChannelChartData();
            },
            deep: true
        }
    },
    mounted() {

        //在组件mounted时绑定resize事件，当窗口大小发生变化时自动调整图表大小
        window.addEventListener("resize", this.handleResize);
        //创建Echarts实例并绘制饼状图
        this.createCharts();
    },
    beforeDestroy(){
        //在组件销毁前解绑resize事件
        window.removeEventListener("resize", this.handleResize);

        //清空图表
        this.pieChart.clear();
        this.pieChart.dispose();
    },
    created() {
    },
    methods: {
        changeTimeFrame(val){
            this.timeFrame = val;
            this.getChannelChartData();
        },
        changeMerchantName(val){
            this.merchantName = val;
            this.getChannelChartData();
        },
        getChannelChartData(){
            if(this.isLoading) return;

            let reqParam = {
                identify: this.timeFrame==null ? "":this.timeFrame,
                merId: this.merchantName==null ? "":this.merchantName,
                queryType: this.queryType
            }

            this.$loading(this.$t('msg.loading'));

            this.isLoading = true;

            this.$athlon.request.post('transAnalysis/getChannelChartData', reqParam, this.$athlon.config.api.requestParamheaders).then((res) => {
                this.$loading(false);
                if(this.dealGlobalResponse(res)) return;

                if(!res.data || res.code != 0){
                    this.tipsMsg(this.$t('msg.systemError'));
                    return;
                }

                if(res.data.result.channelData != undefined && res.data.result.channelName !=undefined){
                    var pieData = [];
                    for(var i=0; i<res.data.result.channelData.length; i++){
                        var item = {
                            value: res.data.result.channelData[i],
                            name: res.data.result.channelName[i]
                        }
                        pieData.push(item);
                    }
                    this.chartData = pieData;
                }else{
                    this.chartData = [];
                }

                this.isLoading = false;
            }).catch(() => {
                this.$loading(false);
                this.isLoading = false;
                this.$router.push('/error/500');
            });
        },

        changeQueryType(val){
            this.queryType = val;
            this.createCharts();
        },

        async createCharts(){
            this.getChannelChartData();
            this.renderChart(this.chartData);
        },

        //处理resizes事件，调整图表大小
        handleResize(){
            if(this.pieChart){
                //调用Echarts实例的resize方法，重新绘制图表
                this.pieChart.resize();
            }
        },
        renderChart(chartData){
            const pieDom = this.$refs.chart;
            if(!pieDom) return;

            let pieChart = null;
            if(this.pieChart){
                this.pieChart.dispose();
                this.pieChart.clear();
            }

            pieChart = echarts.init(pieDom);

            var unit = '';
            if (this.queryType == 'amount'){
                unit = 'HK$';
            }

            var self = this;
            let option;
            if(chartData.length > 0){
                option = {
                    title: {
                        text: '',
                        textStyle: {
                            fontWeight: "normal",
                            fontSize: 16
                        },
                        left: "center"
                    },
                    color: ["#6395F9","#62DAAB","#657798","#F6C022","#E96C5B","#74CBED","#FF9D4E","#299998"],
                    tooltip: {
                        trigger: "item",
                        formatter: "{b}: {d}%"
                    },
                    series: [
                        {
                            type: "pie",
                            //radius: ["60%","90%"],
                            top: 6,
                            bottom: 25,
                            left: "center",
                            textStyle: {
                                color: "#999",
                                fontSize: "12px"
                            },
                            itemWidth: 6,
                            itemHeight: 6,
                            label: {
                                show: true,
                                formatter: function(params){
                                    const percent = params.percent+'%';
                                    const value = self.queryType == 'number' ? params.value : params.value.toFixed(2);

                                    return [`${params.name}\n`+unit+` ${value} (${percent})`].join('\n');
                                }
                            },
                            data: this.chartData
                        }
                    ]
                }
            }else{
                const noData = this.$t('transAnal.noData');

                option = {
                    title: {
                        text: '',
                        textStyle: {
                            fontWeight: "normal",
                            fontSize: 16
                        },
                        left: "center"
                    },
                    tooltip: {
                        trigger: "none",
                    },
                    color: ["#d3d3d3"],
                    series: [
                        {
                            type: "pie",
                            //radius: ["60%","90%"],
                            left: "center",
                            label: {
                                show: true,
                                formatter: "{b}"
                            },
                            data: [{ value: 1, name: noData}]
                        }
                    ]
                }
            }

            pieChart.resize();
            pieChart.setOption(option);
            this.pieChart = pieChart;
        }
    },
};
</script>

<style>
    .v-card__title{
        background: #C4161C;
        border-top-left-radius: 0px!important;
    }

    .ath-card-title{
        color: white;
    }

    .ath-card-title[data-v-32f2b3d1]:before {
        display: none;
    }
</style>

