<template>
    <div class="mt-4 mx-4" style="width: 100%;overflow-x: auto;">
        <div style="min-width: 1000px;">
            <ath-breadcrumbs :items="navigationItems" flat="false" txtColor="" bgColor=""/>
            <v-row class="analysis">
                <v-col md="5" style="padding-left:0px;">
                    <v-autocomplete  v-model="channelId" :label="this.$t('transAnal.channel')"
                        :items="channelItems"
                        :item-text="'label'"
                        :item-value="'value'"
                        @change="changeChannel"/>
                </v-col>
                <v-col md="5" style="padding-left:0px;">
                    <v-autocomplete  v-model="merchantId" :label="this.$t('transAnal.merchant')"
                        :items="merchantItems"
                        :item-text="'label'"
                        :item-value="'value'"
                        @change="changeMerchant"/>
                </v-col>
                <v-col class="btn_refresh" style="padding-right:0px;">
                    <ath-button class="primary" style="float: right;" icon="mdi-refresh" :text="this.$t('action.refresh')" @click="refresh"/>
                </v-col>
            </v-row>

            <v-row class="line-center analysis" style="margin-top: 0px!important;">
                <percent-statistic type="day" :allTransactionStatistics="getAllTransactionStatistics" :lang="this.$athlon.i18n.locale"/>
                <percent-statistic  type="month" :allTransactionStatistics="getAllTransactionStatistics" :lang="this.$athlon.i18n.locale"/>
                <percent-statistic  type="year" :allTransactionStatistics="getAllTransactionStatistics" :lang="this.$athlon.i18n.locale"/>
            </v-row>

            <v-row class="analysis" style="display: inline-block;">
                <ath-tabs class="mb-5" ref="t1" style="margin-bottom: 0px!important;"
                :value="selectedTab"
                :tabs="items"
                @change="onChange"
                >
                </ath-tabs>

                <payment-channel-card ref="paymentChannel" v-if="selectedTab == 0" class="transcard" :merchants="merchantItems" :lang="this.$athlon.i18n.locale"/>
                <merchant-ranking-card ref="merchantRanking" v-if="selectedTab == 1" class="transcard" :lang="this.$athlon.i18n.locale"/>
                <monthly-trading-volume-card ref="monthlyTradingVolume" v-if="selectedTab == 2" class="transcard" :channels="channelItems" :merchants="merchantItems" :lang="this.$athlon.i18n.locale"/>
            </v-row>
        </div>
    </div>
</template>

<script>
import PercentStatistic from './percent_statistic.vue';
import PaymentChannelCard from './payment_channel_card.vue';
import MerchantRankingCard from './merchant_ranking_card.vue';
import MonthlyTradingVolumeCard from './monthly_trading_volume_card.vue';

export default {
    name: 'tradeAnalysis',
    components: {
        PercentStatistic,
        PaymentChannelCard,
        MerchantRankingCard,
        MonthlyTradingVolumeCard
    },
    data() {
        return {
            merchantId: '',
            channelId: '',
            allTransactionStatistics: null,
            navigationItems: [
                {text:this.$t('menu.transAnalysis'),disabled:true,to:''}
            ],
            channelItems: [],
            merchantItems: [],
            selectedTab: 0,
            items: [
                    { text:  this.$t('transAnal.paymentChannelAnalysis'), show: true, pending: 0 },
                    { text: this.$t('transAnal.merchantRanking'), show: true, pending: 0 },
                    { text: this.$t('transAnal.monthlyProportionTradingVolume'), show: true, pending: 0 },
            ],
            timer1: null,
            timer2: null,
            isLoading: false
        }
    },
    watch: {
        '$athlon.i18n.locale': {
            handler(newValue) {
                this.navigationItems = [
                    {text:this.$t('menu.transAnalysis'),disabled:true,to:''}
                ];
                this.items = [
                    { text:  this.$t('transAnal.paymentChannelAnalysis'), show: true, pending: 0 },
                    { text: this.$t('transAnal.merchantRanking'), show: true, pending: 0 },
                    { text: this.$t('transAnal.monthlyProportionTradingVolume'), show: true, pending: 0 },
                ];
                this.getMerAndChannel();
            },
            deep: true
        }
    },
    created() {
        this.getMerAndChannel();
        this.getPercentage();
    },
    computed: {
        getAllTransactionStatistics(){
            return this.allTransactionStatistics;
        }
    },
    mounted(){
        //页面图表定时刷新，页面跳转时，需要清除定时器
        this.timer1 = setInterval(this.refresh, 60000*10); // 每10分钟刷新页面数据
        this.timer2 = setInterval(this.changeTab, 60000*15); // 每15分钟切换tab页
    },
    destroyed(){
        //页面跳转时，清除定时器
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        this.timer1 = null;
        this.timer2 = null;
    },
    methods: {
        changeChannel(val){
            this.channelId = val;
            this.getPercentage();
        },
        changeMerchant(val){
            this.merchantId = val;
            this.getPercentage();
        },
        /* 查询交易数据统计(当天，当月，当年) */
        getPercentage(){
            if(this.isLoading) return;
            let reqParam = {
                merId: this.merchantId==null ? "":this.merchantId,
                channelId: this.channelId==null ? "":this.channelId
            }

            this.$loading(this.$t('msg.loading'));

            this.isLoading = true;

            this.$athlon.request.post('transAnalysis/getPercentage', reqParam, this.$athlon.config.api.requestParamheaders).then((res) => {
                this.$loading(false);
                if(this.dealGlobalResponse(res)) return;

                if(!res.data || res.code != 0){
                    this.tipsMsg(this.$t('msg.systemError'));
                    return;
                }

                this.allTransactionStatistics = res.data.result;

                this.isLoading = false;
            }).catch(() => {
                this.$loading(false);
                this.isLoading = false;
                this.$router.push('/error/500');
            });
        },

        /**获取渠道和商户下拉选项 */
        getMerAndChannel(){
            var lang = this.$athlon.i18n.locale;
            let reqParam = {
                langCode: lang.replace("-","_")
            }

            this.$athlon.request.post('transAnalysis/getDataDict', reqParam, this.$athlon.config.api.requestParamheaders).then((res) => {
                    this.$loading(false);
                    if(this.dealGlobalResponse(res)) return;

                    if(!res.data || res.code != 0){
                        this.tipsMsg(this.$t('msg.systemError'));
                        return;
                    }


                    if(res.data.result.channels != null){
                        this.channelItems = [];
                        var channels = res.data.result.channels;
                        this.channelItems.push({ label: this.$t("athlon.common.all"), value:''});
                        for(var i=0; i< channels.length; i++){
                            var channelItem = {
                                label : channels[i].dictName,
                                value : channels[i].dictCode
                            }
                            this.channelItems.push(channelItem);
                        }
                    }

                    if(res.data.result.merchants != null){
                        this.merchantItems = [];
                        var merchants = res.data.result.merchants;
                        this.merchantItems.push({ label: this.$t("athlon.common.all"), value:''});
                        for(var i=0; i< merchants.length; i++){
                            var merchantItem = {
                                label : merchants[i].merShortName+'('+merchants[i].merId+')',
                                value : merchants[i].merId
                            }
                            this.merchantItems.push(merchantItem);
                        }
                    }

            }).catch(() => {
                this.$loading(false);
                this.$router.push('/error/500');
            });
        },

        refresh(){
            this.getPercentage();   //刷新百分比统计数据
            switch(this.selectedTab){
                case 0:
                default:
                    this.$refs.paymentChannel.createCharts();
                    break;
                case 1:
                    this.$refs.merchantRanking.createCharts();
                    break;
                case 2:
                    this.$refs.monthlyTradingVolume.createCharts();
                    break;
            }
        },

        onChange(value){
            this.selectedTab = value;
        },

        changeTab(){
            switch(this.selectedTab){
                case 0:
                default:
                    this.selectedTab = 1;
                    break;
                case 1:
                    this.selectedTab = 2;
                    break;
                case 2:
                    this.selectedTab = 0;
                    break;
            }
        }

    }
}
</script>

<style>
    .btn_refresh {
        padding-top: 1%;
    }

    .analysis{
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }

    .transcard{
        width: 100%;
    }

    .anal{
        width: 100%;
    }

    .v-tabs-slider-wrapper {
        display: none;
    }

    .v-tab--active {
        background: #C4161C;
        color: #fff !important
    }

    .line-center{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .v-tab{
        align-items: center;
        cursor: pointer;
        display: flex;
        flex: 0 1 auto;
        font-size: .875rem;
        font-weight: 500;
        justify-content: center;
        letter-spacing: .0892857143em;
        line-height: normal;
        min-width: 90px;
        max-width: 360px;
        outline: none;
        padding: 0 16px;
        position: relative;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-style: double;
        /* border-style: dotted; */
        border-color: #C4161C;
        border-width: 2px;
        margin-right: -2px!important;
        border-bottom: none;
    }

    .v-application ul{
        padding-left: 1%;
    }

    .v-breadcrumbs {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 auto;
        list-style-type: none;
        margin: 0;
        padding: 4px 12px;
    }

</style>
