<template>
    <div class="transaction-amount" style="display:inline; width: 32%;">
        <div id="amount-money" class="active" style="width: 100%;">
            <div class="amount-money-day">
            <span class="amount-money-left" style="background: rgba(196, 22, 28, 1)">{{$t('transAnal.transAmount')}} ({{$t('transAnal.HK$')}})</span>
            <span class="amount-money-right" style="background: rgba(196, 22, 28, 1)">{{$t('transAnal.transNumber')}}</span>
            </div>

            <div class="amount-money-day">
            <span class="amount-money-left" style="background: rgba(196, 22, 28, 1)">{{this.unitText}}</span>
            <span class="amount-money-right" style="background: rgba(196, 22, 28, 1)">{{this.unitText}}</span>
            </div>

            <div class="amount-money-specific" style="height: 90px;">
            <div class="amount-money-specific-left">
                <span class="amount-money-top">{{ transactionStatistics.amountSpecific }}</span>
                <span class="amount-money-bottom">
                <span v-if="transactionStatisticsPositive.amountPercentagePositive > 0" > ▲ {{ transactionStatistics.amountPercentage }}% </span>
                <span v-else-if="transactionStatisticsPositive.amountPercentagePositive < 0" style="color: #a81317"> ▼ {{ transactionStatistics.amountPercentage }}% </span>
                <span v-else-if="transactionStatisticsPositive.amountPercentagePositive === 0"> {{ transactionStatistics.amountPercentage }}% </span>
                <div class="amount-money-display money" v-show="isEn_US" style="line-height: 15px;">{{this.compareTypeText}}</div>
                <div class="amount-money-display money" style="line-height: 15px;">{{this.compareTypeText}}</div>
                </span>
            </div>

            <div class="amount-money-specific-right">
                <span class="amount-money-top">
                    {{ transactionStatistics.numbersSpecific }}
                </span>

                <span class="amount-money-bottom">
                <span v-if="transactionStatisticsPositive.numbersPercentagePositive > 0"> ▲ {{ transactionStatistics.numbersPercentage }}% </span>
                <span v-else-if="transactionStatisticsPositive.numbersPercentagePositive < 0" style="color: #a81317"> ▼ {{ transactionStatistics.numbersPercentage }}% </span>
                <span v-else-if="transactionStatisticsPositive.numbersPercentagePositive === 0"> {{ transactionStatistics.numbersPercentage }}% </span>
                <div class="amount-money-display" v-show="isEn_US" style="line-height: 15px;">{{$t('sys.dashboard.compareWith1')}}</div>
                <div class="amount-money-display" style="line-height: 15px;">{{this.compareTypeText}}</div>

                </span>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'percentStatistic',
    props: {
        lang: {
            type: String,
            default: '',
            required: true
        },
        type: {
            type: String,
            default: '',
            required: true
        },
        allTransactionStatistics: {
            type: Object,
            default: {},
            required: true
        }
    },
    data() {
        return {
            compareTypeText: '',
            unitText: '',
            transactionStatistics: {
                amountSpecific: '$0.0',
                numbersSpecific: '0',
                amountPercentage: '0.0',
                numbersPercentage: '0.0'
            },
            transactionStatisticsPositive:
            {
            amountPercentagePositive: 1,
            numbersPercentagePositive: 1,
            },
        }
    },
    watch: {
        lang: {
            handler(newValue) {
                this.initCompareType();
            },
            deep: true
        },
        allTransactionStatistics: {
            handler(newValue) {
                this.initCompareType();
            },
            deep: true
        }
    },
    computed: {

    },
    created() {
        this.initCompareType();
    },
    methods: {
        initCompareType(){
            switch(this.type){
                default:
                case 'day':
                    this.compareTypeText = this.$t('transAnal.compareWithYesterday');
                    this.unitText = this.$t('transAnal.today');
                    if(this.allTransactionStatistics !=null){
                        this.transactionStatistics.amountSpecific = this.allTransactionStatistics.amountSpecificToday;
                        this.transactionStatistics.numbersSpecific = this.allTransactionStatistics.numbersSpecificToday;
                        this.transactionStatistics.amountPercentage = this.allTransactionStatistics.amountPercentageToday;
                        this.transactionStatistics.numbersPercentage = this.allTransactionStatistics.numbersPercentageToday;
                        this.setTransactionStatistics(this.transactionStatistics);
                    }

                    break;
                case 'month':
                    this.compareTypeText = this.$t('transAnal.comparedWithSamePeriodLastMonth');
                    this.unitText = this.$t('transAnal.currentMonth');

                    if(this.allTransactionStatistics !=null){
                        this.transactionStatistics.amountSpecific = this.allTransactionStatistics.amountSpecificMonth;
                        this.transactionStatistics.numbersSpecific = this.allTransactionStatistics.numbersSpecificMonth;
                        this.transactionStatistics.amountPercentage = this.allTransactionStatistics.amountPercentageMonth;
                        this.transactionStatistics.numbersPercentage = this.allTransactionStatistics.numbersPercentageMonth;
                        this.setTransactionStatistics(this.transactionStatistics);
                    }
                    break;
                case 'year':
                    this.compareTypeText = this.$t('transAnal.comparedWithSamePeriodLastYear');
                    this.unitText = this.$t('transAnal.currentYear');

                    if(this.allTransactionStatistics !=null){
                        this.transactionStatistics.amountSpecific = this.allTransactionStatistics.amountSpecificYear;
                        this.transactionStatistics.numbersSpecific = this.allTransactionStatistics.numbersSpecificYear;
                        this.transactionStatistics.amountPercentage = this.allTransactionStatistics.amountPercentageYear;
                        this.transactionStatistics.numbersPercentage = this.allTransactionStatistics.numbersPercentageYear;
                        this.setTransactionStatistics(this.transactionStatistics);
                    }
                    break;
            }
        },

        setTransactionStatistics(transactionStatistics) {

            if (transactionStatistics.amountPercentage != undefined && transactionStatistics.amountPercentage.includes('-'))
            {
                this.transactionStatisticsPositive.amountPercentagePositive = -1;
                this.transactionStatistics.amountPercentage = transactionStatistics.amountPercentage.replace('-','');
            }
            else if (transactionStatistics.amountPercentage === '0.0'){
                this.transactionStatisticsPositive.amountPercentagePositive = 0;
            }

            if (transactionStatistics.numbersPercentage != undefined && transactionStatistics.numbersPercentage.includes('-'))
            {
                this.transactionStatisticsPositive.numbersPercentagePositive = -1;
                this.transactionStatistics.numbersPercentage= transactionStatistics.numbersPercentage.replace('-','');
            }
            else if (transactionStatistics.numbersPercentage === '0.0'){
                this.transactionStatisticsPositive.numbersPercentagePositive = 0;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
   .transaction-amount{
    overflow: hidden;
  }
  .transaction-amount .list{
    width: 180px;
    float: left;
  }
  .transaction-amount .active{
    opacity: 1!important;
  }
  .transaction-amount #amount-money{
    float: left;
    margin-top: 10px;
    margin-bottom: 20px;
    opacity: 0.5;
    box-shadow: -5px 5px 5px #888888
  }
  .transaction-amount #amount-money .active{
    opacity: 1;
  }
  .transaction-amount #amount-money .amount-money-day{
    height: 25px;
    color: #ffffff
  }
  .transaction-amount #amount-money .amount-money-specific{
    overflow: hidden;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-specific-left{
    width: 50%;
    float: left;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-specific-right{
    width: 50%;
    float: left;
    border-left: 1px solid #bfbfbf;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-specific-left .amount-money-bottom{
    position: relative;
    color: #4dc86f;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-bottom .amount-money-display{
    color:black;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-specific-right .amount-money-bottom{
    position: relative;
    color: #4dc86f;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-specific-left .amount-money-bottom .downward-triangle{
    width: 0;
    height: 0;
    border-width: 9px 5px 0;
    position: relative;
    top:12px;
    left: -2px;
    border-style: solid;
    border-color: rgb(190, 12, 12) transparent transparent;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-top{
    height: 35px;
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 20px
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-bottom{
    display: block;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color:rgb(190, 12, 12);
    position: relative;
  }
  .transaction-amount #amount-money .amount-money-specific .amount-money-bottom .up_triangle{
    width: 0;
    height: 0;
    border-width: 0 5px 9px;
    position: relative;
    top:-12px;
    left: -2px;
    border-style: solid;
    border-color: #4dc86f transparent transparent;
  }
  .transaction-amount #amount-money .amount-money-day .amount-money-left{
    width: 50%;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    font-size: 0.8vw;
  }
  .transaction-amount #amount-money .amount-money-day .amount-money-right{
    width: 50%;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    font-size: 0.8vw;
  }
</style>
