<template>
    <div class="ath-demo-component">
        <ath-card class="my-5" :title="this.$t('transAnal.monthlyCompare')" style="margin-top: 0px!important; margin-bottom: 20px!important;">
            <template v-slot:card-title-action>
                <v-sheet style="font-size:12px;line-height:15px;color: white;background: #C4161C;">
                {{$t('transAnal.remark2')}}</v-sheet>
            </template>

            <template v-slot:card-body>
                <v-row>
                    <v-col md="2">
                        <ath-radio style="width:80%;" class="justify-space-between"
                            v-model="btnValue"
                            :align="align"
                            :items="btnItems"
                            :direction="direction"
                            @change="changeQueryType"
                        />
                    </v-col>

                    <v-col>
                        <v-autocomplete  v-model="channelId" :label="$t('transAnal.channel')"
                            :items="channelItems"
                            :item-text="'label'"
                            :item-value="'value'"
                            @change="changeChannel"/>
                    </v-col>
                    <v-col>
                        <v-autocomplete  v-model="merchant" :label="$t('transAnal.merchant')"
                            :items="merchantItems"
                            :item-text="'label'"
                            :item-value="'value'"
                            @change="changeMerchant"/>
                    </v-col>
                </v-row>

                <v-row>
                    <div ref="line" style="width: 100%; height: 400px;"/>
                </v-row>
            </template>
        </ath-card>
    </div>
</template>

<script>
export default {
    name: 'MonthlyTradingdVolumeCard',
    components: {
    },
    props: {
        lang: {
            type: String,
            default: '',
            required: true
        },
        channels: {
            type: Array,
            default: [],
            required: true
        },
        merchants: {
            type: Array,
            default: [],
            required: true
        },
        charTitle: {
            type: String
        }
    },
    data() {
        return {
            channelItems: this.channels,
            merchantItems: this.merchants,
            channelId: "",
            merchant: "",
            queryType: "amount",  //amount/number
            btnItems: [
				{ label: this.$t('transAnal.amount'), value: 'amount', disabled: false },
				{ label: this.$t('transAnal.number'), value: 'number', disabled: false },
			],
            chart: null,
            btnValue: "amount",
            direction: 'row',
            currentYear: [],
            upperCurrentYear: [],
            currentYearName: '',
            upperCurrentYearName: '',
            isLoading: false

        }

    },
    watch: {
        merchants: {
            handler(newValue) {
                this.merchantItems = newValue;
            },
            deep: true
        },
        channels: {
            handler(newValue) {
                this.channelItems = newValue;
            },
            deep: true
        },
        lang: {
            handler(newValue) {
                this.btnItems = [
                    { label: this.$t('transAnal.amount'), value: 'amount', disabled: false },
				    { label: this.$t('transAnal.number'), value: 'number', disabled: false },
                ];

                this.createCharts();
            },
            deep: true
        },
    },
    mounted() {
        //在组件mounted时绑定resize事件，当窗口大小发生变化时自动调整图表大小
        window.addEventListener("resize", this.handleResize);
        //创建Echarts实例并绘制折线图
        this.createCharts();
    },
    beforeDestroy(){
        //在组件销毁前解绑resize事件
        window.removeEventListener("resize", this.handleResize);

        this.lineChart.clear();
        this.lineChart.dispose();
    },
    methods: {
        createCharts(){
            this.getChannelChartData();
        },

        //处理resizes事件，调整图表大小
        handleResize(){
            if(this.lineChart){
                //调用Echarts实例的resize方法，重新绘制图表
                this.lineChart.resize();
            }
        },

        getChannelChartData(){
            if(this.isLoading) return;

            let reqParam = {
                channelId: this.channelId==null ? "":this.channelId,
                merId: this.merchant==null ? "":this.merchant,
                queryType: this.queryType
            }

            this.$loading(this.$t('msg.loading'));

            this.isLoading = true;

            this.$athlon.request.post('transAnalysis/getTransAmountByMonth', reqParam, this.$athlon.config.api.requestParamheaders).then((res) => {
                    this.$loading(false);
                    if(this.dealGlobalResponse(res)) return;

                    if(!res.data || res.code != 0){
                        this.tipsMsg(this.$t('msg.systemError'));
                        return;
                    }

                    if(res.data.retCode == 0){
                        this.currentYear = res.data.result.currentYear.data;
                        this.upperCurrentYear = this.queryType == 'number'?res.data.result.upperCurrentYear.data : this.moneyformat(res.data.result.upperCurrentYear.data);
                        this.currentYearName = res.data.result.currentYear.name;
                        this.upperCurrentYearName = res.data.result.upperCurrentYear.name;

                        this.renderChart();
                    }

                    this.isLoading = false;
            }).catch(() => {
                this.$loading(false);
                this.isLoading = false;
                this.$router.push('/error/500');
            });
        },

        moneyformat(list){
            var formatList = [];
            for(let i=0; i<list.length; i++){
                formatList.push(list[i].toFixed(2));
            }
            return formatList;
        },

        renderChart(){
            const lineDom = this.$refs.line;
            if(!lineDom) return;

            let lineChart = null;
            if(this.lineChart){
                this.lineChart.dispose();
                this.lineChart.clear();
            }

            lineChart = echarts.init(lineDom);

            var monthlyAxis;
            if (this.queryType == 'number'){
                monthlyAxis = this.$t('transAnal.number');
            }else{
                monthlyAxis = this.$t('transAnal.HK$');
            }

            let option;

            option = {
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    x: 'center',
                    y: '10px',
                    data:[this.upperCurrentYearName,this.currentYearName]
                },
                calculable : true,
                xAxis : [
                    {
                        show: true,
                        type : 'category',
                        data :
                        [
                            this.$t('year.jan'),
                            this.$t('year.feb'),
                            this.$t('year.mar'),
                            this.$t('year.apr'),
                            this.$t('year.may'),
                            this.$t('year.jun'),
                            this.$t('year.jul'),
                            this.$t('year.aug'),
                            this.$t('year.sep'),
                            this.$t('year.oct'),
                            this.$t('year.nov'),
                            this.$t('year.dec')
                        ],
                        axisLabel: {
                            interval: 0
                        },
                        axisLine: {
                            show: true
                        }
                    }
                ],
                yAxis : [
                    {
                        show: true,
                        type : 'value',
                        name: monthlyAxis,
                        minInterval: 1,
                        axisLabel: {
                            interval: 0
                        },
                        axisLine: {
                            show: true
                        }
                    }
                ],
                series : [
                    {
                        name: this.upperCurrentYearName,
                        type:'line',
                        data: this.upperCurrentYear,
                        label:
                        {
                            normal: {
                                show: true,
                                position: 'top'
                            }
                        }
                    },
                    {
                        name:this.currentYearName,
                        type:'line',
                        data: this.currentYear,
                        label:
                        {
                            normal: {
                                show: true,
                                position: 'top'
                            }
                        }
                    }
                ]
            }

            lineChart.resize();
            lineChart.setOption(option);
            this.lineChart = lineChart;
        },

        changeQueryType(val){
            this.queryType = val;
            this.createCharts();
        },

        changeChannel(val){
            this.channelId = val;
            this.createCharts();
        },

        changeMerchant(val){
            this.merchant = val;
            this.createCharts();
        },
    },
};
</script>

<style>
    .v-card__title{
        background: #C4161C;
        border-top-left-radius: 0px!important;
    }

    .ath-card-title{
        color: white;
    }

    .ath-card-title[data-v-32f2b3d1]:before {
        display: none;
    }
</style>
