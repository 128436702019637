<template>
    <div class="ath-demo-component">
        <ath-card class="my-5" :title="this.$t('transAnal.storeSales')" style="margin-top: 0px!important; margin-bottom: 20px!important;">
            <template v-slot:card-title-action>
                <v-sheet style="font-size:12px;color: white;background: #C4161C;">
                {{$t('transAnal.remark1')}}</v-sheet>
            </template>

            <template v-slot:card-body>
                <v-row>
                    <v-col md="2">
                        <ath-radio  style="width:80%;" class="justify-space-between"
                            v-model="queryType"
                            :align="align"
                            :items="btnItems"
                            :direction="direction"
                            @change="changeQueryType"
                        />
                    </v-col>

                    <v-col md="4">
                        <ath-radio  class="justify-space-between" style="width:80%;"
                            v-model="shopArea"
                            :align="align"
                            :items="mapItems"
                            :direction="direction"
                            @change="refreshMap"
                        />
                    </v-col>

                    <v-col>
                        <v-autocomplete  v-model="timeFrame"  :label="$t('transAnal.timeFrame')"
                            :items="timeFrameItems"
                            :item-text="'label'"
                            :item-value="'value'"
                            @change="changeTimeFrame"/>
                    </v-col>
                </v-row>

                <v-row>
                    <div ref="map"  :style="'width: 50%; height: '+mapHeight+'px;'"/>
                    <div ref="bar" :style="'width: 50%; height: '+mapHeight+'px;'"/>
                </v-row>
            </template>
        </ath-card>
    </div>
</template>

<script>
export default {
    name: 'MerchantRankingCard',
    components: {
    },
    props: {
        lang: {
            type: String,
            default: '',
            required: true
        }
    },
    data() {
        return {
            timeFrameItems: [
                { label: this.$t('transAnal.today'), value: 'day' },
                { label: this.$t('transAnal.last7Day'), value: '7day' },
                { label: this.$t('transAnal.currentMonth'), value: 'month' },
                { label: this.$t('transAnal.last3Month'), value: '3month' },
                { label: this.$t('transAnal.last6Month'), value: '6month' },
                { label: this.$t('transAnal.currentYear'), value: 'year' },
                { label: this.$t('transAnal.lastYear'), value: 'lastyear' },
            ],
            btnItems: [
				{ label: this.$t('transAnal.amount'), value: 'amount', disabled: false },
				{ label: this.$t('transAnal.number'), value: 'number', disabled: false },
			],
            mapItems: [
				{ label: this.$t('transAnal.hongKongMap.all'), value: 'all', disabled: false },
				{ label: this.$t('transAnal.hongKongMap.hki'), value: 'il', disabled: false },
                { label: this.$t('transAnal.hongKongMap.kw'), value: 'kl', disabled: false },
                { label: this.$t('transAnal.hongKongMap.nt'), value: 'nt', disabled: false },
                { label: this.$t('transAnal.hongKongMap.oi'), value: 'oi', disabled: false }
			],
            timeFrame: "day",
            direction: 'row',
            shopArea: "all",
            queryType: "amount",  //amount/number
            mapChart: null,
            barChart: null,
            geoCoordMap: {},
            dataMap: [],
            echartsAmount: 0.0,
            echartsCount: 0,
            shopStoreName:[],
            shopStoreData:[],
            mapHeight: '',
            isLoading: false
        }
    },
    watch: {
        lang: {
            handler(newValue) {
                this.timeFrameItems = [
                    { label: this.$t('transAnal.today'), value: 'day' },
                    { label: this.$t('transAnal.last7Day'), value: '7day' },
                    { label: this.$t('transAnal.currentMonth'), value: 'month' },
                    { label: this.$t('transAnal.last3Month'), value: '3month' },
                    { label: this.$t('transAnal.last6Month'), value: '6month' },
                    { label: this.$t('transAnal.currentYear'), value: 'year' },
                    { label: this.$t('transAnal.lastYear'), value: 'lastyear' },
                ];
                this.btnItems = [
                    { label: this.$t('transAnal.amount'), value: 'amount', disabled: false },
                    { label: this.$t('transAnal.number'), value: 'number', disabled: false },
                ];
                this.mapItems = [
                    { label: this.$t('transAnal.hongKongMap.all'), value: 'all', disabled: false },
                    { label: this.$t('transAnal.hongKongMap.hki'), value: 'il', disabled: false },
                    { label: this.$t('transAnal.hongKongMap.kw'), value: 'kl', disabled: false },
                    { label: this.$t('transAnal.hongKongMap.nt'), value: 'nt', disabled: false },
                    { label: this.$t('transAnal.hongKongMap.oi'), value: 'oi', disabled: false }
                ];

                this.createCharts();
            },
            deep: true
        }
    },
    mounted() {
        //监控浏览器窗口变化
        const self = this;
        window.onresize = function(){
            self.setMapHeight();
        }

        //在组件mounted时绑定resize事件，当窗口大小发生变化时自动调整图表大小
        window.addEventListener("resize", this.handleResize);
        //创建Echarts实例并绘制饼状图
        this.createCharts();
    },
    beforeDestroy(){
        //在组件销毁前解绑resize事件
        window.removeEventListener("resize", this.handleResize);

        this.mapChart.clear();
        this.barChart.clear();
        this.mapChart.dispose();
        this.barChart.dispose();
    },
    created () {
        this.setMapHeight();
    },
    methods: {
        changeTimeFrame(val){
            this.timeFrame = val;
            this.getShopAreaChartData();
        },

        getShopAreaChartData(){
            if(this.isLoading) return;

            let reqParam = {
                identify: this.timeFrame==null ? "":this.timeFrame,
                shopArea: this.shopArea==null ? "":this.shopArea,
                queryType: this.queryType
            }

            this.$loading(this.$t('msg.loading'));

            this.isLoading = true;

            this.$athlon.request.post('transAnalysis/getShopAreaChartsData', reqParam, this.$athlon.config.api.requestParamheaders).then((res) => {
                    this.$loading(false);
                    if(this.dealGlobalResponse(res)) return;


                    if(!res.data || res.code != 0){
                        this.tipsMsg(this.$t('msg.systemError'));
                        return;
                    }

                    this.shopStoreName = [];
                    this.shopStoreData = [];
                    if(res.data.result.data){
                        this.geoCoordMap = res.data.result.geoCoordMap;
                        this.dataMap = res.data.result.data;

                        this.echartsAmount = res.data.result.echartsAmount;
                        this.echartsCount = res.data.result.echartsCount;


                        for (var i = 0; i < this.dataMap.length; i++)
                        {
                            this.shopStoreName.push(this.dataMap[i].name);

                            this.queryType == 'number' ? this.shopStoreData.push(this.dataMap[i].value) : this.shopStoreData.push(this.dataMap[i].value.toFixed(2));
                        }

                    }

                this.renderMapChart();
                this.renderbarChart();

                this.isLoading = false;

            }).catch(() => {
                this.$loading(false);
                this.isLoading = false;
                this.$router.push('/error/500');
            });
        },

        async createCharts(){
            this.getShopAreaChartData();
        },

        changeQueryType(val){
            this.queryType = val;
            this.createCharts();
        },

        //处理resizes事件，调整图表大小
        handleResize(){
            if(this.barChart){
                //调用Echarts实例的resize方法，重新绘制图表
                this.barChart.resize();
            }

            if(this.mapChart){
                //调用Echarts实例的resize方法，重新绘制图表
                this.mapChart.resize();
            }
        },

        refreshMap(val){
            this.shopArea = val;
            this.createCharts();
        },

        renderMapChart(){
            const mapDom = this.$refs.map;
            if(!mapDom) return;

            let mapChart = null;
            if(this.mapChart){
                this.mapChart.dispose();
                this.mapChart.clear();
            }
            mapChart = echarts.init(mapDom);

            var shopArea = this.shopArea;
            var geoCoordMap = this.geoCoordMap;
            var areaAmount;
            if(this.queryType == 'amount'){
                areaAmount = true;
            }else{
                areaAmount = false;
            }

            var convertData = function (data){
                var res = [];

                for (var i = 0; i < data.length; i++){
                    if ((data[i].type === shopArea) || (shopArea === 'all')){
                        var geoCoord = geoCoordMap[data[i].id];
                        if (geoCoord) {
                            res.push({
                                name: data[i].name,
                                value: geoCoord.concat(data[i].value)
                            });
                        }
                    }
                }

                return res;
            };

            var nameStr = areaAmount?this.$t("transAnal.amount"):this.$t("transAnal.number");
            var self = this;

            var option =  {
                tooltip:
                {
                    trigger: 'item',
                    showDelay: 0,
                    transitionDuration: 0.2,
                    formatter: function (params) {
                        var value = (params.value + '').split(',');
                        var c = value[2];

                        var d = Number(c).toLocaleString();
                        if(c.indexOf(".") < 0){
                            if(areaAmount)
                              d = d+".00";
                        }

                        value = areaAmount?"HK$ "+d:d;
                        return params.seriesName + '<br/>' + params.name + ': ' + value;
                    }
                },
                grid: {
                    left: '8%',
                    right: 0,
                    bottom: '1%',
                    containLabel: true
                },
                geo: {
                    map: self.shopArea,
                    silent: true,
                    roam: true,
                    label:
                    {
                        emphasis: {
                            show: false,
                            areaColor: '#eee'
                        }
                    },
                    itemStyle:
                    {
                        normal: {
                            color:'#000',
                            borderColor:'#888888',
                            borderWidth:0.5,
                            areaColor: 'rgba(222, 224, 227, 1)',
                            areaStyle: {
                                color: '#bfbfbf'
                            }
                        }
                    }
                },
                series: [
                    {
                        name: nameStr,
                        type: 'effectScatter',
                        mapType: this.shopArea,
                        coordinateSystem: 'geo',
                        showEffectOn: 'emphasis',
                        symbolSize: function (val)
                        {
                            if(val[2] === 0){
                                return 5;
                            }else{
                                var temp = 0;

                                if(areaAmount)
                                    temp = Math.floor((val[2]/100)*10) > 3 ? 3:temp;

                                if(temp === 0)
                                    temp = 1;

                                return 6*temp;
                            }
                        },
                        label: {
                            normal: {
                                formatter: '{b}',
                                position: 'top',
                                show: false
                            }
                        },
                        rippleEffect:{
                            scale: 10,
                            brushType: 'stroke'
                        },
                        itemStyle:{
                            normal: {
                                color: '#a81317'
                            },
                            emphasis: {
                                color: '#a81317',
                                borderColor:'#a81317',
                                borderWidth:10
                            }
                        },
                        data:convertData(self.dataMap)
                    }
                ]
            }

            mapChart.resize();
            mapChart.setOption(option, true);

            this.mapChart = mapChart;
        },

        renderbarChart(){
            const barDom = this.$refs.bar;
            if(!barDom) return;

            let barChart = null;
            if(this.barChart){
                this.barChart.dispose();
                this.barChart.clear();
            }

            barChart = echarts.init(barDom);

            var shopFormatter;
            var shopAxis;
            if (this.queryType == 'number'){
                shopFormatter= this.$t('transAnal.number') + '<br/>{b}: {c}';
                shopAxis = this.$t('transAnal.unitNumber');
            }else{
                shopFormatter= this.$t('transAnal.amount') + '<br/>{b}: HK$ {c}';
                shopAxis = this.$t('transAnal.HK$');
            }

            let option = {
                    grid: {
                        left: '3%',
                        right: '8%',
                        bottom: '3%',
                        top: '3%',
                        containLabel: true
                    },
                    tooltip : {
                        trigger: 'item',
                        formatter: shopFormatter
                    },
                    xAxis: {
                        show: true,
                        type: 'value',
                        minInterval: 1,
                        name: shopAxis,
                        nameTextStyle: {
                            lineHeight: 10,
                            padding: [-5, 0, 0, 0],
                            verticalAlign: 'top',
                            fontSize: 11
                        },
                        axisLabel:{
                            interval:0,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid'
                            }
                        },
                        axisTick: {
                            show: true,
                            lehgth: 5,
                            alignWithLabel: true
                        },
                        min: 0,
                        max: this.shopStoreData == 0 ? 1:null
                    },
                    yAxis: {
                        type: 'category',
                        data: this.shopStoreName,
                        inverse: true, //数据从大到小倒序排序
                        axisLabel:{
                            interval:0
                        },
                        axisLine: {
                            show: true
                        },
                        axisTick: {
                            show: true,
                            lehgth: 5,
                            alignWithLabel: true
                        },
                        min: 0,
                        max: this.shopStoreName == 0 ? "":null
                    },
                    series:
                    [
                        {
                            type: 'bar',
                            data: this.shopStoreData,
                            label:
                            {
                                normal: {
                                    show: true,
                                    position: 'right'
                                }
                            }
                        }
                    ]

                };

            if(this.shopStoreName.length == 0 && this.shopStoreData.length == 0){
                const noData = this.$t('transAnal.noData');
                option.graphic = {
                    type: 'text',
                    left: 'center',
                    top: 'middle',
                    silent: true,
                    invisiable: this.shopStoreData,
                    style: {
                        fill: '#c7c0c0',
                        fontWeight: 'bold',
                        text: noData,
                        fontSize: '1.2vm'
                    }
                }
            }

            barChart.resize();
            barChart.setOption(option);

            this.barChart = barChart;
        },

        setMapHeight(){
            var height = window.innerHeight || document.documentElement.clientHeight;
            var temp = height-474-35;
            var min_height = 300;

            if (temp < min_height)
                this.mapHeight = min_height;
            else this.mapHeight = temp;
        }

    },
};
</script>

<style>
    .v-card__title{
        background: #C4161C;
        border-top-left-radius: 0px!important;
    }

    .ath-card-title{
        color: white;
    }

    .ath-card-title[data-v-32f2b3d1]:before {
        display: none;
    }
</style>
